<template>
    <div>
        <table class="cart">
            <tr>
                <th>Hústípus</th>
                <th>Mennyiség</th>
                <th>Fizetendő összeg</th>
            </tr>
            <tr v-if="currentRoute == 'checkout.shop'" v-for="item in items">
                <td>{{ item.cut.part.name }} <span>({{ item.cut.price_huf }} Ft/kg)</span></td>
                <td>{{ item.weight }} kg</td>
                <td>{{ item.cut.price_huf * item.weight }} Ft</td>
            </tr>
            <tr v-if="currentRoute == 'checkout.pre-order'" v-for="item in items">
                <td>{{ item.cattle_part.name }} <span>({{ item.cattle_part.cut.price_huf }} Ft/kg)</span></td>
                <td>{{ item.weight }} kg</td>
                <td>{{ item.cattle_part.cut.price_huf * item.weight }} Ft</td>
            </tr>
             <tr>
                <td></td>
                <td></td>
                <td>{{ total }} Ft</td>
            </tr>
        </table>

        <div class="center">
            <a href="" class="button next" :class="[formIsValid ? '' : 'disabled']" @click.prevent="saveForm">Megrendelés elküldése</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            currentRoute: String
        },
        data() {
            return {
                items: [],
                checkoutData: 0
            }
        },
        computed: {
            total: function ()  {
                let total = 0;
                if (this.currentRoute == 'checkout.shop') {
                    this.items.forEach(item => {
                        total += item.cut.price_huf * item.weight;
                    });
                } else {
                    this.items.forEach(item => {
                        total += item.cattle_part.cut.price_huf * item.weight;
                    });
                }

                return total;
            },
            formIsValid: function () {
                if (this.checkoutData) {
                    if (this.checkoutData.needShipping) {
                        if (this.checkoutData.shippingData.name == '') return false;
                        if ($.isEmptyObject(this.checkoutData.shippingData.address)) return false;
                    } 

                    if (this.checkoutData.billingData.name == '') return false;
                    if ($.isEmptyObject(this.checkoutData.billingData.address)) return false;

                    if (this.checkoutData.shippingMethod == '') return false;
                    if (this.checkoutData.paymentMethod == '') return false;

                    return true;
                } else {
                    return false;
                }
            }
        }, 
        beforeMount() {
            if (Vue.localStorage.get('shop_cart_items') != null || Vue.localStorage.get('pre_order_cart_items') != null) {
                if (this.currentRoute == 'checkout.shop') {
                    this.items = JSON.parse(Vue.localStorage.get('shop_cart_items'));
                } else {
                    this.items = JSON.parse(Vue.localStorage.get('pre_order_cart_items'));
                }
            }
        },
        mounted () {
            this.$root.$emit('get-total', this.total);
            this.$root.$on('send-form-data', (checkoutData) => {
                this.checkoutData = checkoutData;
            });
        },
        methods: {
            validateForm() {
                this.$root.$emit('get-form-data');
            },
            saveForm() {
                let params = {};
                if (this.currentRoute == 'checkout.shop') {
                    var route = '/order/store?params=';
                    params = {
                        items: this.items.map(object => object.id),
                        order: this.checkoutData
                    }
                } else {
                    var route = '/pre-order/store?params=';
                    params = {
                        items: this.items,
                        order: this.checkoutData
                    }
                }

                axios.get(route + JSON.stringify(params))
                    .then(
                        response => {
                            if (this.currentRoute == 'checkout.shop') {
                                Vue.localStorage.remove('shop_cart_items');
                                window.location = response.data.redirect;
                            } else {
                                Vue.localStorage.remove('pre_order_cart_items');
                                window.location = response.data.redirect;
                            }
                        }
                    ).catch();
            }
        }
    }
</script>