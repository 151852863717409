var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "map",
        style:
          "background-position: " +
          this.current_dot.mapPosition.x +
          "px" +
          " " +
          this.current_dot.mapPosition.y +
          "px"
      },
      [
        _c(
          "div",
          { staticClass: "wrap" },
          [
            _c(
              "transition",
              { attrs: { name: "slide-fade", mode: "out-in" } },
              [
                _c(
                  "div",
                  {
                    key: _vm.current_dot.title.text,
                    staticClass: "topic show"
                  },
                  [
                    _c("h1", [_vm._v(_vm._s(_vm.current_dot.title.text))]),
                    _vm._v(" "),
                    _vm._l(_vm.current_dot.content.paragraphs, function(
                      paragraph
                    ) {
                      return _c("p", { staticClass: "text" }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(paragraph) +
                            "\n                    "
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "first-page-picture",
                      class: _vm.current_dot.content.imageClass
                    }),
                    _vm._v(" "),
                    _vm.current_dot.left
                      ? _c(
                          "div",
                          { staticClass: "nav-left" },
                          _vm._l(_vm.current_dot.left, function(id) {
                            return _c(
                              "div",
                              {
                                staticClass: "button menu",
                                class: _vm.dots[id].title.class,
                                on: {
                                  click: function($event) {
                                    return _vm.setupAnimation(id)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-angle-left" }),
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.dots[id].title.text) +
                                    "\n                        "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.current_dot.right
                      ? _c(
                          "div",
                          { staticClass: "nav-right" },
                          _vm._l(_vm.current_dot.right, function(id) {
                            return _c(
                              "div",
                              {
                                staticClass: "button menu",
                                class: _vm.dots[id].title.class,
                                on: {
                                  click: function($event) {
                                    return _vm.setupAnimation(id)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.dots[id].title.text) +
                                    "\n                            "
                                ),
                                _c("i", { staticClass: "fa fa-angle-right" })
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrap canvas-container" }, [
      _c("canvas", {
        attrs: { id: "myCanvas", height: "200px", width: "870px" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }