<template>
    <div>
        <div class="gray-knife pre">
            <div class="wrap pre"> 
                <div class="knife-description">            
                    <p class="number-knife-one">1.</p>
                    <p class="text-knife">Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot.</p>
                    <p class="number-knife-one">2.</p>
                    <p class="text-knife">A zsútos alomok azt szengetették, hogy a hadt vikezések.</p>
                    <p class="number-knife-one">3.</p>
                    <p class="text-knife">Mányom szengetették nem rönges eles baságokról ketitálnia hozzá.</p>          
                </div>     

                <div class="timeline">
                    <div class="month-bar">
                        <div class="month" v-for="month in months" :style="setWidth(month.length)">
                            <span>{{ getMonthName(month.month) }}</span>
                        </div>
                    </div>    

                    <div class="cutting select" v-for="cattle in cattles" :style="calculateCattlePosition(cattle)">
                        <div class="knife"></div>
                        <div class="mark"></div>
                        <div class="cut-cow"></div>
                        <div class="range">
                            <div class="avaliable" :style="setPercentWidth(cattle)"></div>
                        </div>
                        <div class="hover-cut-card">                 
                            <div class="arrow-up"></div>
                            <div class="cut-card" @click="selectCattle(cattle)">
                                <div class="cut-card-title">{{ cattle.type.name }}</div>
                                <div class="cut-card-cow"></div>
                                <div class="range">
                                    <div class="avaliable" :style="setPercentWidth(cattle)"></div>
                                </div>
                                <div class="percent">{{ getCattlePercent(cattle).toFixed(2) }}%</div>
                                <div class="date">{{ cattle.cut_date }}</div>
                                <div class="enar">enar szám:</div>
                                <div class="hu-number">{{ cattle.enar }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="beef-sheet">
            <div v-if="selected" class="center-center">
                <table>
                    <tr>
                        <td>vágás időpontja</td>
                        <td>{{ selected.cut_date }}</td>
                    </tr>
                    <tr>
                        <td>típus</td>
                        <td>{{ selected.type.name }} {{ selected.is_grown_up == 0 ? 'borjú' : '' }}</td>
                    </tr>
                    <tr>
                        <td>ENAR szám</td>
                        <td>{{ selected.enar }}</td>
                    </tr>
                    <tr>
                        <td>Bontás típus</td>
                        <td>{{ selected.cut_type == 'hungarian' ? 'magyar' : 'amerikai' }} bontás</td>
                    </tr>
                    <tr>
                        <td>maradt</td>
                        <td>
                            <div class="range">
                                <div class="avaliable" :style="setPercentWidth(selected)"></div>
                            </div>
                            {{ getCattlePercent(selected).toFixed(2) }}%
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    
</template>


<script>
    export default {
        data() {
            return {
                cattles: [],
                selected: 0,
                months: [],
                sold: 0
            }
        },
        beforeMount() {
            if (Vue.localStorage.get('pre-order-selected') != null && Vue.localStorage.get('pre_order_cart_items') != null) {
                this.selected = JSON.parse(Vue.localStorage.get('pre-order-selected'));
                this.$root.$emit('pre-order-select-cattle', this.selected);
            }
        },

        mounted() {
            this.getResults();
            this.getMonths();
        },
        methods: {
            getResults() {
                axios.get('/pre-order-list')
                    .then(
                        response => {
                            this.cattles = response.data.cattles;
                        }
                    ).catch();
            },
            calculateCattlePosition(cattle) {
                const today = new Date();
                const cut_date = new Date(cattle.cut_date);

                const diffTime = Math.abs(cut_date - today);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                return "left: " + ((diffDays-1)*12) + 'px';
            },
            getMonths() {
                const today = new Date();
                var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0);

                const diffTime2 = Math.abs(lastDayOfMonth - today);
                const diffDays2 = Math.ceil(diffTime2 / (1000 * 60 * 60 * 24)); 

                let first = diffDays2;
                let second = new Date(today.getFullYear(), today.getMonth()+2, 0).getDate();
                let third = new Date(today.getFullYear(), today.getMonth()+3, 0).getDate();
                let forth = new Date(today.getFullYear(), today.getMonth()+4, 0).getDate();

                this.months.push({length: first, month: today.getMonth()+1});

                this.months.push({length: second, month: today.getMonth()+2});

                this.months.push({length: third, month: today.getMonth()+3});

                if (first + second + third < 90) {
                    this.months.push({length: 90 - first - second - third, month: today.getMonth()+4});
                } 
            },
            setWidth(days) {
                return "width: " + (days*12) + 'px';
            },
            getMonthName(currentmonth) {
                const today = new Date();
                const date = new Date(today.getFullYear(), currentmonth-1, 1);  // 2009-11-10
                const month = date.toLocaleString('default', { month: 'long' });
                return month;
            },
            selectCattle(cattle) {
                this.selected = cattle;
                Vue.localStorage.set('pre-order-selected', JSON.stringify(this.selected));
                this.$root.$emit('pre-order-select-cattle', this.selected);
            },
            getCattlePercent(cattle) {
                axios.get('/cattle-sold/' + cattle.id)
                    .then(
                        response => {
                            this.sold = response.data.sold;
                        }
                    ).catch();

                return 100 - this.sold/cattle.weight*100;
            },
            setPercentWidth(cattle) {
                return "width: " + this.getCattlePercent(cattle).toFixed(2) + '%';
            }
        }
    }
</script>