<template>
    <div v-if="params.cattle">
        <div class="wrap">
            <svg id="beef_map" viewBox="0 0 321.8 266.8">
                <path @click="selectCattlePart(6)" :class="checkCattlePart(6)" d="M113.02723,202.01851a47.36843,47.36843,0,0,0,27.40942-4.62744c-3.3465-12.27149-1.62756-24.11035,5.13892-35.19776,6.7871-11.12158,12.57031-24.80224,4.00634-41.94433A34.64638,34.64638,0,0,0,137.765,106.67134c-1.05591,3.52588-2.00928,7.03857-2.92627,10.42969-3.64453,13.47705-6.792,25.11621-14.00586,30.71094a16.2317,16.2317,0,0,1-10.06836,3.69921,13.72108,13.72108,0,0,1-3.8894-.55127c1.43365,4.37159,1.163,9.00977.96655,12.37256-.02881.49268-.05567.95459-.07471,1.38135l-.0415.88281c-.29737,6.25684-.993,20.90821,3.41589,33.481C111.76087,200.00874,112.39264,200.99458,113.02723,202.01851Z"/>
                <path @click="selectCattlePart(3)" :class="checkCattlePart(3)" d="M89.87635,116.84859a60.6115,60.6115,0,0,0-10.27741,25.72509c3.10089-1.60546,11.96039-5.33642,20.03913-.58007.50268.29589.971.60839,1.4165.93066-1.55731-17.76709,6.145-35.36475,8.38946-40.04736A37.06458,37.06458,0,0,0,89.87635,116.84859Z"/>
                <path @click="selectCattlePart(22)" :class="checkCattlePart(22)" d="M156.34705,157.76656c-3.72565.47314-6.60309.97607-7.47833,1.13476-.73126,1.33887-1.49268,2.645-2.26874,3.917a42.22418,42.22418,0,0,0-4.85718,34.82129c1.69177-.10742,7.10944-.4917,12.18866-1.27832C150.56635,179.16109,155.14215,161.84761,156.34705,157.76656Z"/>
                <path @click="selectCattlePart(11)" :class="checkCattlePart(11)" d="M263.639,102.24751c-.377-3.76416-6.76269-5.44336-18.97314-4.99072-10.82569.40137-17.52832,1.99707-19.92334,4.74268a3.67736,3.67736,0,0,0-.9502,2.91357c.51612,5.17725,12.72168,5.1001,21.85938,4.59131,11.84277-.65918,15.81689-3.33594,17.13183-4.87256A3.135,3.135,0,0,0,263.639,102.24751Z"/>
                <path @click="selectCattlePart(4)" :class="checkCattlePart(4)" d="M108.91028,195.893c-3.24737-11.87207-2.65613-24.60938-2.38354-30.35352l.041-.87939c.01953-.43164.04688-.89941.07617-1.398.33252-5.69531.88965-15.228-7.61425-20.23437-8.33307-4.90479-17.93244.064-19.6579,1.03857a70.23418,70.23418,0,0,0,.21936,21.20752C88.22687,171.43062,99.68781,183.4023,108.91028,195.893Z"/>
                <path @click="selectCattlePart(12)" :class="checkCattlePart(12)" d="M273.33332,130.07173c-.00421-.75439-.009-1.51416-.0141-2.28418a244.07665,244.07665,0,0,1,1.41046-32.91162,37.2083,37.2083,0,0,0-12.55811,3.47168,4.91806,4.91806,0,0,1,2.66181,3.78027,4.34462,4.34462,0,0,1-1.13819,3.28369c-1.578,1.84473-5.42138,4.1128-14.63214,5.02881a44.92619,44.92619,0,0,0-8.97192,17.88575C250.57178,128.68648,261.71021,129.24654,273.33332,130.07173Z"/>
                <path @click="selectCattlePart(21)" :class="checkCattlePart(21)" d="M174.68738,155.89741c9.75855,1.1587,14.98718,3.8252,17.09375,15.23975,2.05493-.08789,10.49085-1.13916,16.59961-12.20166,6.2865-11.38379,8.71643-26.45068,9.21-29.91309-34.85418-.36523-58.84906,1.32325-63.14276,1.65235,1.96106,10.252-.82934,19.12646-4.86035,26.87939C153.55536,156.86616,166.16712,154.8857,174.68738,155.89741Z"/>
                <path @click="selectCattlePart(7)" :class="checkCattlePart(7)" d="M110.18378,199.80318c-.43079.019-.94336.03173-1.49737.03173a28.10359,28.10359,0,0,1-3.02912-.15087c2.08429,7.15771,2.7879,15.94628,2.84534,24.168a40.9483,40.9483,0,0,0,10.14648.87647,50.41237,50.41237,0,0,1,1.22968-6.69629C118.11554,213.02827,113.901,205.39448,110.18378,199.80318Z"/>
                <path @click="selectCattlePart(17)" :class="checkCattlePart(17)" d="M307.91,110.1938a33.02616,33.02616,0,0,0-11.19245-11.06592c-.26727,6.84766-1.38928,47.56006,8.58393,66.27149,4.83545-7.41895,6.86633-15.33692,8.16937-23.82617l.20721-4.55567a38.63026,38.63026,0,0,0-.07031-4.90576C312.8241,122.14009,310.96033,114.97066,307.91,110.1938Z"/>
                <path @click="selectCattlePart(7)" :class="checkCattlePart(7)" d="M287.59308,212.17134a42.8223,42.8223,0,0,1,.85016,4.22119c2.41315-.0708,8.11359-.6543,9.52735-2.68847l.01452.01025a141.99743,141.99743,0,0,1,1.36719-14.60547A37.68329,37.68329,0,0,1,296.88349,182.58a35.86326,35.86326,0,0,0-1.51056,16.00977l-.271.62451C291.11371,201.707,288.7179,205.84126,287.59308,212.17134Z"/>
                <path @click="selectCattlePart(7)" :class="checkCattlePart(7)" d="M267.59278,194.20308a67.31876,67.31876,0,0,1,4.9223,23.65332c1.34357.228,7.5387,1.15918,12.58124-.02393.694-3.46533,1.32965-6.65429,1.7605-8.10937.009.02539.01648.05273.02539.07861,1.09265-4.41406,2.94024-7.76807,5.61242-10.124a33.42786,33.42786,0,0,1-4.12658.26367A43.82411,43.82411,0,0,1,267.59278,194.20308Z"/>
                <path @click="selectCattlePart(10)" :class="checkCattlePart(10)" d="M196.29035,127.84273c12.2265-.15332,26.62353-.085,42.57428.44287a47.24107,47.24107,0,0,1,8.51739-17.69678c-.54211.041-1.08752.08154-1.66388.11377-2.48291.13818-4.98291.23584-7.374.23584-8.28955,0-15.27393-1.17432-15.74609-5.90625a4.88293,4.88293,0,0,1,1.24023-3.82129c2.6582-3.04785,9.45606-4.7334,20.78272-5.15332,7.53942-.2793,12.91754.25879,16.22918,1.62744a36.14956,36.14956,0,0,1,14.02777-4.042c.02344-.17773.03925-.34863.06336-.52686,1.12341-8.29736,2.40283-14.21386,2.775-15.85888-7.535.21142-16.87579,1.82519-21.71374,2.4209-13.94287,1.71728-30.93579,1.0913-53.59125.77685A319.43264,319.43264,0,0,1,196.29035,127.84273Z"/>
                <path @click="selectCattlePart(16)" :class="checkCattlePart(16)" d="M303.98548,165.46431q-3.6839-7.16969-5.82733-18.94824c-10.79206.186-22.71131,4.54736-24.7005,5.3042-1.60309,9.80175-5.5033,18.13281-14.83826,25.67382a88.02143,88.02143,0,0,1,8.27576,15.01368C268.47663,188.771,278.55567,167.54781,303.98548,165.46431Z"/>
                <path @click="selectCattlePart(7)" :class="checkCattlePart(7)" d="M119.02381,203.62691a44.18685,44.18685,0,0,1-5.19019-.28907,97.1568,97.1568,0,0,1,6.63056,12.87452,28.68088,28.68088,0,0,1,1.32153,2.75976c.65369,1.7417,1.2276,3.53272,1.73419,5.333a40.6314,40.6314,0,0,0,11.93561-1.80566c.83966-9.64209,2.70917-18.57618,5.29077-23.92188A48.24971,48.24971,0,0,1,119.02381,203.62691Z"/>
                <path @click="selectCattlePart(20)" :class="checkCattlePart(20)" d="M237.54529,139.35493a45.674,45.674,0,0,1,1.047-9.87793c-6.90649-.22656-13.52149-.36767-19.79163-.44189-.45178,3.22119-2.89251,18.751-9.36914,30.48047-6.317,11.439-14.88263,12.708-17.44574,12.82226.05341.33887.10565.68067.15424,1.03418,1.741,12.65918,5.072,17.77,5.20282,17.96631,38.87488-.05664,56.616-11.3081,60.18127-13.895C244.70527,169.83589,237.60877,156.33052,237.54529,139.35493Z"/>
                <path @click="selectCattlePart(19)" :class="checkCattlePart(19)" d="M238.74549,139.35054c.0614,16.51172,6.93084,29.63769,19.33807,37.02783,14.58923-11.92187,15.3349-26.04,15.25562-45.103-11.72986-.834-22.9643-1.39746-33.528-1.75781A44.53094,44.53094,0,0,0,238.74549,139.35054Z"/>
                <path @click="selectCattlePart(5)" :class="checkCattlePart(5)" d="M106.32666,149.50435c1.20075.49853,7.11066,2.52441,13.77088-2.64063,6.896-5.34814,10.144-17.35937,13.58252-30.07568.94867-3.50781,1.9364-7.145,3.03589-10.79443a32.07026,32.07026,0,0,0-14.22681-4.542,35.78148,35.78148,0,0,0-11.49292.98584c-1.11841,2.19922-10.81232,21.96729-8.635,41.5376A15.471,15.471,0,0,1,106.32666,149.50435Z"/>
                <path class="disabled" d="M135.35,223.78853a41.58986,41.58986,0,0,1-11.50183,1.71924,103.53856,103.53856,0,0,1,3.21716,23.40966c0,6.29883-.78735,6.65674-2.362,8.51758-4.28039,5.0586-3.184,8.34424-3.184,8.34424,7.97962,2.96484,15.13727-1.47266,15.13727-1.47266l-.13647-1.76367a6.56662,6.56662,0,0,1,1.1054-6.27441l.09406-.11621C135.05732,247.86177,134.45417,235.414,135.35,223.78853Z"/>
                <path @click="selectCattlePart(23)" :class="checkCattlePart(23)" d="M190.95154,173.5356c-1.74463-12.69092-6.26562-15.24268-16.40576-16.44678-5.00421-.59424-11.60333-.10644-16.8974.51856-.8421,2.727-6.03442,20.72412-2.53174,38.562a39.72082,39.72082,0,0,0,5.87781-1.375c8.46735-2.894,19.72125-3.9585,26.96527-3.65088,2.75769.11719,5.40509.17041,7.97955.18457C194.93518,189.52632,192.41144,184.15279,190.95154,173.5356Z"/>
                <path class="disabled" d="M118.512,225.93062c-.541.03174-1.08393.05615-1.62348.05615a43.80925,43.80925,0,0,1-8.38025-.90625,200.36187,200.36187,0,0,1-1.62335,24.78174c-6.79443,3.90576-6.18408,10.0918-6.18408,10.0918,6.384,2.60644,14.83014-.27442,14.83014-.27442a19.54492,19.54492,0,0,1,2.90009-9.418A124.51531,124.51531,0,0,1,118.512,225.93062Z"/>
                <path class="disabled" d="M282.47742,229.36177c.69562-2.09766,1.55615-6.24219,2.36353-10.25684a27.99566,27.99566,0,0,1-5.56812.52344,43.27969,43.27969,0,0,1-6.74408-.55127c.00684,18.4917-8.19269,31.11572-10.17578,33.41553a10.73384,10.73384,0,0,0-7.20032,9.772c9.15387,5.05908,18.87677-1.35742,18.87677-1.35742-.00085-7.0542,4.21906-8.92773,4.21906-8.92773C276.96405,247.04488,278.67749,240.82222,282.47742,229.36177Z"/>
                <path @click="selectCattlePart(18)" :class="checkCattlePart(18)" d="M267.97205,193.03706c2.28,1.35108,13.559,7.48535,26.125,5.17285a38.72359,38.72359,0,0,1,4.74945-23.26562,21.58827,21.58827,0,0,1,3.18927-5.126c.88281-1.05322,1.67908-2.12011,2.43073-3.19384C279.25428,168.48042,269.38843,189.65279,267.97205,193.03706Z"/>
                <path class="disabled" d="M297.92048,215.39351c-2.599,1.82519-7.70307,2.14013-9.30787,2.19482,1.82581,13.95557-.97058,31.915-.97058,31.915a15.77015,15.77015,0,0,0-7.36151,10.28516,33.91128,33.91128,0,0,0,8.21088,1.605c8.95794,0,11.30627-4.44483,11.30627-4.44483a11.46643,11.46643,0,0,1,1.11249-13.34961C298.079,234.87837,297.63288,224.13911,297.92048,215.39351Z"/>
                <path @click="selectCattlePart(13)" :class="checkCattlePart(13)" d="M320.78626,180.05562c-3.18958-14.752-4.09247-64.46778-5.53088-71.65967-2.41852-12.09277-19.00757-27.3125-29.299-30.43652a24.9738,24.9738,0,0,0-7.00439-.72315c-.24274,1.04932-1.62067,7.17285-2.82129,16.041-.01239.09131-.02032.1792-.03253.27051a43.0885,43.0885,0,0,1,7.69806.19824c10.92285,1.26172,19.1416,6.43066,25.12549,15.80224,3.15869,4.94678,5.083,12.29688,5.88232,22.46924a39.92672,39.92672,0,0,1,.07276,5.05664l-.14728,3.24317c1.4519,22.28173,3.15173,31.624,3.15173,31.624-7.87439,5.54541-4.88788,18.05664-1.43378,22.89355a9.65627,9.65627,0,0,1,1.54285,5.2168.76337.76337,0,0,0,1.46722.27246C321.92194,194.70113,322.56946,188.30269,320.78626,180.05562Z"/>
                <path @click="selectCattlePart(22)" :class="checkCattlePart(22)" d="M109.38727,198.62593c-.41168-.59766-.81738-1.17529-1.20624-1.70459-9.00714-12.25781-20.20269-24.03076-28.7539-30.27783,1.498,4.70508,8.97149,25.57373,25.19525,31.665A22.78237,22.78237,0,0,0,109.38727,198.62593Z"/>
                <path @click="selectCattlePart(14)" :class="checkCattlePart(14)" d="M283.65857,94.93794a41.2597,41.2597,0,0,0-7.70819-.16064,203.46491,203.46491,0,0,0-1.44867,24.58691,92.017,92.017,0,0,0,21.0044-.00293c-.37091-10.56152-.04676-19.00244.03711-20.88525A33.63093,33.63093,0,0,0,283.65857,94.93794Z"/>
                <path @click="selectCattlePart(8)" :class="checkCattlePart(8)" d="M99.41694,106.1523a37.63722,37.63722,0,0,1,23.16546-5.897,33.29053,33.29053,0,0,1,14.49341,4.54981c2.759-8.94873,6.28461-17.88721,11.74322-25.147-27.67089-1.25293-38.03314-3.97119-55.351-5.38477-.37152-.03027-.74908-.05762-1.13025-.083C92.54548,76.6313,94.42511,97.18111,99.41694,106.1523Z"/>
                <path @click="selectCattlePart(9)" :class="checkCattlePart(9)" d="M138.12751,105.4731a35.77223,35.77223,0,0,1,12.52765,14.23974,42.90776,42.90776,0,0,1,3.54473,9.77735c2.95606-.229,17.972-1.3125,40.86139-1.63135a318.055,318.055,0,0,0,6.14966-47.41992c-5.54712-.07373-11.42724-.126-17.68512-.11914-13.42419.01465-24.24859-.21533-33.27185-.59912C144.55707,87.02681,140.95264,96.25288,138.12751,105.4731Z"/>
                <path @click="selectCattlePart(15)" :class="checkCattlePart(15)" d="M295.5506,120.55611a80.82743,80.82743,0,0,1-10.16058.61767,97.63627,97.63627,0,0,1-10.894-.60254c-.00617,2.47315.00744,4.88086.02288,7.208.05579,8.40918.10108,15.89551-.85437,22.68359,3.23163-1.18457,14.2002-4.92529,24.29-5.14257a184.72179,184.72179,0,0,1-2.22564-20.96534Q295.62332,122.42281,295.5506,120.55611Z"/>
                <path class="disabled" d="M32.45234,75.27c2.48016,6.415,11.86218,3.87647,13.19158,3.478,1.761-2.1499,3.28248-6.94043,3.38477-7.26611a47.92591,47.92591,0,0,0,.44952-13.71533,33.67715,33.67715,0,0,0-2.69714-7.356c-4.4671-8.86768-9.67895-14.11182-15.22864-23.32569-5.60522-9.30664-6.48022-19.92578-6.37561-26.417A.65119.65119,0,0,0,23.88623.53511c-1.86291,8.937-3.03784,27.34033,9.74231,50.92139C41.74567,66.43355,34.49976,73.63374,32.45234,75.27Z"/>
                <path @click="selectCattlePart(1)" :class="checkCattlePart(1)" d="M75.56305,73.92427c-7.2182-.01416-13.67883-.20068-16.58105-1.72559-1.24329.47071-4.0564,1.11621-8.96552.12745-.47607,1.43164-1.8955,5.40283-3.57373,7.33593l-.26367.17627a22.031,22.031,0,0,1-6.10986.93408c-3.30713,0-7.032-.96875-8.64917-4.835A40.364,40.364,0,0,0,15.45,94.7895c-1.73926,4.16114-6.2826,24.50538-11.35859,39.23829,0,0-4.55908,7.98828-4.05206,8.92968a15.38044,15.38044,0,0,0,17.79,7.38965l9.02307-2.584s3.81079-.126,9.95227,3.49853c.542.31983,1.06073.61572,1.58209.91406C69.719,129.062,75.1449,78.39058,75.56305,73.92427Z"/>
                <path @click="selectCattlePart(2)" :class="checkCattlePart(2)" d="M88.89881,116.15279a38.14166,38.14166,0,0,1,9.523-9.32178c-5.35993-9.50733-7.16407-31.07276-7.29425-32.71289-4.515-.23926-9.56568-.19336-14.35962-.19239-.353,3.855-5.64716,55.16065-37.24372,78.89454,22.10553,12.35449,25.35553,5.22265,35.17005,10.13086a32.536,32.536,0,0,1,3.65466,2.1914C77.567,160.62837,74.31122,136.64253,88.89881,116.15279Z"/>
                <path class="disabled" d="M50.72486,58.02681a48.24761,48.24761,0,0,1-.4176,13.12744c4.88867.97314,7.49578.23047,8.40478-.1416,11.28168-11.99854,4.963-25.96436-.84943-37.85352-2.1-4.29541-7.87268-13.47314-6.64428-27.21191a.6502.6502,0,0,0-1.25806-.28613c-1.26978,3.41406-2.45673,9.69043-.428,19.70507C51.70313,36.083,57.24476,50.74214,50.72486,58.02681Z"/>
            </svg>
            <div class="meat-type">
                <h2>hústípus</h2>
                <span class="button chooser" :class="checkCattlePart(cattle_part.id)" @click="selectCattlePart(cattle_part.id)" v-for="cattle_part in cattle_parts">{{ cattle_part.name }}</span>
            </div>
            <div class="meat-type">
                <span class="button chooser">gulyáshús</span>
                <span class="button chooser">velős csont</span>
            </div>
        </div>

        <div class="product-select wrap" v-if="params.cattle_part">
            <div class="data-sheet">
                <div class="data">
                    <h3>{{ params.cattle_part.name }} <a class="info">i</a></h3>
                    <div class="range">
                        <div class="avaliable" :style="setPercentWidth()"></div>
                    </div>
                    {{ cattle_part_percent }} %
                    
                </div>
                <div class="data">
                    <span>Ár</span>
                    {{ params.cattle_part.cut.price_huf }} Ft/kg
                </div>
                <div class="data">
                    <span>Rendelkezésre álló mennyiség:</span>
                    {{ available }} kg
                </div>
                <div class="data">
                    <span>Szállítás várható időpontja:</span>
                    2020. szeptember 13. 
                </div>
            </div>
            <div class="center">

                <div :class="'pre-order quantity ' + sliderClass">
                    <span class="chosen">{{ params.weight }} kg</span>
                    <vue-slider 
                        v-model="params.weight"
                        :data="sliderPoints"
                        :absorb="true"
                        :interval="3"
                        :marks="true">
                    </vue-slider>
                </div>

                <div class="sum">
                    <div>{{ params.weight*params.cattle_part.cut.price_huf }} Ft</div>
                    <a href="" class="button next" @click.prevent="addToCart">KOSÁRBA TESZEM</a>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
    import VueSlider from 'vue-slider-component'
    import 'vue-slider-component/theme/default.css'
    export default {
        props: {
            previousRoute: String
        },
        components: {
            VueSlider
        },
        data() {
            return {
                params: {
                    weight: 0,
                    cattle_part: '',
                    cattle: 0,
                    sold: 0,
                    max: 10
                },
                cattle_parts: 0
            }
        },
        computed: {
            available: function ()  {
                return (this.params.max - this.params.sold).toFixed(0);
            },
            cattle_part_percent: function () {
                return (100 - this.params.sold/this.params.max*100).toFixed(2);
            },
            sliderPoints: function () {
                var data = [];
                for (var i = this.params.cattle_part.cut.min; i <= this.available; i = i + this.params.cattle_part.cut.min) {
                    data.push(i);
                }
                return data;
            },
            sliderClass: function () {
                if (this.sliderPoints.length <= 20) return 'one';
                if (this.sliderPoints.length > 20 && this.sliderPoints.length <= 30) return 'two';
                if (this.sliderPoints.length > 30 && this.sliderPoints.length <= 40) return 'three';
                if (this.sliderPoints.length > 40 && this.sliderPoints.length <= 75) return 'four';
                if (this.sliderPoints.length > 75 && this.sliderPoints.length <= 100) return 'five';
                if (this.sliderPoints.length > 100) return 'six';
            }       
        },
        beforeMount() {
            if (Vue.localStorage.get('pre-order-selected') != null && this.previousRoute == 'pre-order.index') {
                if (Vue.localStorage.get('pre-order-params') != null) {
                    this.params = JSON.parse(Vue.localStorage.get('pre-order-params'));
                    this.params.max = this.params.cattle.weight*(this.params.cattle_part.cut.cattle_percent/100);
                } else {
                    this.params.cattle = JSON.parse(Vue.localStorage.get('pre-order-selected'));
                }
            } else {
                Vue.localStorage.remove('pre-order-params');
                this.params.cattle = JSON.parse(Vue.localStorage.get('pre-order-selected'));
            }
        },
        mounted() {
            this.getCattleParts();

            this.$root.$on('pre-order-select-cattle', (cattle) => {
                this.params.cattle = cattle;
                this.getCattleParts();
            });
        },
        methods: {
            getResults() {
                Vue.localStorage.set('pre-order-params', JSON.stringify(this.params));
            },
            getCattleParts() {
                axios.get('/cattle-parts?cut_type=' + this.params.cattle.cut_type)
                    .then(
                        response => {
                            this.cattle_parts = response.data.cattle_parts;
                        }
                    ).catch();
            },
            checkCattlePart(id) {
                return this.params.cattle_part.id == id ? 'active' : '';
            },
            setPercentWidth() {
                return "width: " + this.cattle_part_percent + '%';
            },
            selectCattlePart(id) {
                this.params.cattle_part = this.cattle_parts.filter(cattle_part => cattle_part.id == id)[0];

                axios.get('/cattle-part-sold?cattle=' + this.params.cattle.id + '&cattle_part=' + this.params.cattle_part.id)
                    .then(
                        response => {
                            this.params.sold = response.data.sold;
                            this.params.max = this.params.cattle.weight*(this.params.cattle_part.cut.cattle_percent/100);
                            this.params.weight = 0;
                        }
                    ).catch();

                Vue.localStorage.set('pre-order-params', JSON.stringify(this.params));
            },
            addToCart() {
                if (this.params.weight < 0.1) return false;
                this.$root.$emit('pre-order-cart', this.params.cattle_part, this.params.weight, this.params.cattle);
                this.params.weight = 0;
            }
        }
    }
</script>