<template>
    <div class="gray">
        <div class="wrap">
            <div class="cards">
                <div class="card-cow" :class="[isInCart(item) ? 'selected' : '']" v-for="item in items">
                    <div class="code">
                        <div class="name-kg">
                            <div class="part-name">{{ item.cut.part.name }}</div>
                            <div><span>{{ item.cut.price_huf }} Ft/kg</span> </div>
                        </div>
                        <div class="frozen"></div>
                    </div>
                    <div class="pikto-cow"></div>
                    
                    <table class="info card" cellspacing="0">
                        <tr class="last-line">
                            <td>{{ item.cattle.type.name }}</td>
                            <td><span>{{ item.cattle.cut_type }}</span></td>
                        </tr>
                    </table>

                    <span class="enarcod">ENAR kód: <b>{{ item.cattle.enar }}</b></span>

                    <table class="info card" cellspacing="0">
                        <tr class="last-line">
                            <td><b>{{ item.weight }} kg</b></td>
                            <td><span><b>{{ item.cut.price_huf * item.weight }} Ft</b></span></td>
                        </tr>
                    </table> 
                    <div v-if="isInCart(item)" class="button select remove" @click="removeFromCart(item)">
                        kosárból kiveszem
                    </div>
                    <div v-else class="button select" @click="addToCart(item)">
                        kosárba
                    </div>
                </div>
            </div>

            <ul class="pagination">
                <li>
                    <a  :href="'/shop?page=' + (page - 1)"
                        :class="{disabled: page == 1}">
                        <i class="fa fa-chevron-left"></i></a>
                </li>
                <li v-for="index in laravelData.last_page"
                    v-if="laravelData.last_page < 10 || index == 1 || index == laravelData.last_page || page == index || (index > page - 3 && index < page) || (index > page && index < page + 3)">
                    <span v-if="laravelData.last_page > 10 && page > 4 && page < laravelData.last_page - 3 && index == laravelData.last_page">...</span>
                    <a :href="'/shop?page=' + index"
                       :class="{current: page == index}">
                       {{ index }}</a>
                    <span v-if="laravelData.last_page > 10 && page > 4 && page < laravelData.last_page - 3 && index == 1">...</span>
                </li>
                <li>
                    <a  :href="'/shop?page=' + (+page + 1)"
                        :class="{disabled: page == laravelData.last_page}">
                        <i class="fa fa-chevron-right"></i></a>
                </li>
            </ul>
        

        </div>
    </div>
</template>

<script>
    export default {
        props: {
            page: Number
        },
        data() {
            return {
                finished_products: [],
                items: [],
                laravelData: {},
                inCart: []
            }
        },
        beforeMount() {
            if (Vue.localStorage.get('shop_cart_items') != null) {
                this.inCart = JSON.parse(Vue.localStorage.get('shop_cart_items'));
            }
        },
        mounted() {
            this.$root.$on('removed-from-cart', (item) => {
                this.inCart.splice(this.inCart.indexOf(item), 1);
            });
            this.$root.$on('shop-product-list', (params) => {
                this.getResults(this.page, params);
            });
        },
        methods: {
            getResults(page, params = {}) {
                axios.get('/products?page=' + page + '&params=' + JSON.stringify(params))
                    .then(
                        response => {
                            this.finished_products = response.data.finished_products;
                            this.items = response.data.beefs.data;
                            this.laravelData = response.data.beefs;
                        }
                    ).catch();
            },
            addToCart(item) {
                this.inCart.push(item);
                this.$root.$emit('shop-cart', item, true);
            },
            removeFromCart(item) {
                this.$root.$emit('shop-cart', item, false);
            },
            isInCart(item) {
                return this.inCart.findIndex(cart_item => cart_item.id === item.id) !== -1;
            }
        }
    }
</script>