<template>
    <div>
        <div class="mini-cart" :class="[items.length > 0 ? 'open' : 'close']">
            <i class="fa fa-shopping-basket" aria-hidden="true"></i>
            <p>
                <b>{{ items.length }}</b> termék
            </p>
        </div>

        <div id="cart" v-if="items.length > 0">
            <h4>Húspiac kosár</h4>

            <table class="cart">
                <tr>
                    <th>Hústípus</th>
                    <th>Mennyiség</th>
                    <th>Becsült végösszeg</th>
                    <th></th>
                </tr>
                <tr v-for="item in items">
                    <td>{{ item.cut.part.name }} <span>({{ item.cut.price_huf }} Ft/kg)</span></td>
                    <td>{{ item.weight }} kg</td>
                    <td>{{ item.cut.price_huf * item.weight }} Ft</td>
                    <td><span @click="removeFromCart(item)"><i class="fa fa-trash"></i></span></td>
                </tr>
                 <tr>
                    <td></td>
                    <td></td>
                    <td>{{ total }} Ft</td>
                    <td></td>
                </tr>
            </table>

            <div class="center">
                <a :href="checkoutUrl + '?type=shop'" class="button next">Megrendelem</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            checkoutUrl: String
        },
        data() {
            return {
                items: []
            }
        },
        computed: {
            total: function ()  {
                let total = 0;
                this.items.forEach(item => {
                    total += item.cut.price_huf * item.weight;
                });
                return total;
            }
        }, 
        beforeMount() {
            if (Vue.localStorage.get('shop_cart_items') != null) {
                this.items = JSON.parse(Vue.localStorage.get('shop_cart_items'));
            }
        },
        mounted() {
            this.$root.$on('shop-cart', (item, isAdd) => {
                if (isAdd) {
                    this.addToCart(item);
                } else {
                    this.removeFromCart(item);
                }
            });
        },
        methods: {
            addToCart(item) {
                this.items.push(item);
                Vue.localStorage.set('shop_cart_items', JSON.stringify(this.items));
            },
            removeFromCart(item) {
                this.items.splice(this.items.indexOf(item), 1);
                Vue.localStorage.set('shop_cart_items', JSON.stringify(this.items));
                this.$root.$emit('removed-from-cart', item);
            }
        }
    }
</script>