<template>
    <div>
        <div id="cart" v-if="items.length > 0">
            <h4>Előrendelés kosár</h4>

            <table class="cart">
                <tr>
                    <th>Hústípus</th>
                    <th>Mennyiség</th>
                    <th>Becsült végösszeg</th>
                    <th></th>
                </tr>
                <tr v-for="item in items">
                    <td>{{ item.cattle_part.name }} <span>({{ item.cattle_part.cut.price_huf }} Ft/kg)</span></td>
                    <td>{{ item.weight }} kg</td>
                    <td>{{ item.cattle_part.cut.price_huf * item.weight }} Ft</td>
                    <td><span @click="removeFromCart(item)"><i class="fa fa-trash"></i></span></td>
                </tr>
                 <tr>
                    <td></td>
                    <td></td>
                    <td>{{ total }} Ft</td>
                    <td></td>
                </tr>
            </table>

            <div class="center">
                <a :href="checkoutUrl + '?type=pre-order'" class="button next">Megrendelem</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            checkoutUrl: String
        },
        data() {
            return {
                items: []
            }
        },
        computed: {
            total: function ()  {
                let total = 0;
                this.items.forEach(item => {
                    total += item.cattle_part.cut.price_huf * item.weight;
                });
                return total;
            }
        }, 
        beforeMount() {
            if (Vue.localStorage.get('pre_order_cart_items') != null) {
                this.items = JSON.parse(Vue.localStorage.get('pre_order_cart_items'));
            }
        },
        mounted() {
            this.$root.$on('pre-order-cart', (cattle_part, weight, cattle) => {
                this.addToCart(cattle_part, weight, cattle);
            });
        },
        methods: {
            addToCart(cattle_part, weight, cattle) {
                this.items.push({cattle_part: cattle_part, weight: weight, cattle: cattle});
                Vue.localStorage.set('pre_order_cart_items', JSON.stringify(this.items));
            },
            removeFromCart(item) {
                this.items.splice(this.items.indexOf(item), 1);
                Vue.localStorage.set('pre_order_cart_items', JSON.stringify(this.items));
                this.$root.$emit('removed-from-pre-order-cart', item);
            }
        }
    }
</script>