var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "cart" },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.items, function(item) {
          return _vm.currentRoute == "checkout.shop"
            ? _c("tr", [
                _c("td", [
                  _vm._v(_vm._s(item.cut.part.name) + " "),
                  _c("span", [
                    _vm._v("(" + _vm._s(item.cut.price_huf) + " Ft/kg)")
                  ])
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.weight) + " kg")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(item.cut.price_huf * item.weight) + " Ft")
                ])
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _vm._l(_vm.items, function(item) {
          return _vm.currentRoute == "checkout.pre-order"
            ? _c("tr", [
                _c("td", [
                  _vm._v(_vm._s(item.cattle_part.name) + " "),
                  _c("span", [
                    _vm._v(
                      "(" + _vm._s(item.cattle_part.cut.price_huf) + " Ft/kg)"
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.weight) + " kg")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(item.cattle_part.cut.price_huf * item.weight) + " Ft"
                  )
                ])
              ])
            : _vm._e()
        }),
        _vm._v(" "),
        _c("tr", [
          _c("td"),
          _vm._v(" "),
          _c("td"),
          _vm._v(" "),
          _c("td", [_vm._v(_vm._s(_vm.total) + " Ft")])
        ])
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "center" }, [
      _c(
        "a",
        {
          staticClass: "button next",
          class: [_vm.formIsValid ? "" : "disabled"],
          attrs: { href: "" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.saveForm($event)
            }
          }
        },
        [_vm._v("Megrendelés elküldése")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Hústípus")]),
      _vm._v(" "),
      _c("th", [_vm._v("Mennyiség")]),
      _vm._v(" "),
      _c("th", [_vm._v("Fizetendő összeg")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }