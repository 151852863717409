<template>
	<div class="wrap checkout">

	    <h4>Fizetés</h4>

	    <div class="card-body">

	    	<h2>számlázási adatok</h2>

	        <form method="POST" action="">

	            <div class="form-group">
	                <label for="billing_name" class="text-label">Név</label>

	                <div class="">
	                    <input id="billing_name" 
	                    	type="text" 
	                    	class="form-control" n
	                    	ame="billing_name" 
	                    	v-model="checkoutData.billingData.name" 
	                    	required 
	                    	autocomplete="billing_name"
	                    	@change="sendFormData">
	                </div>
	            </div>

	            <div class="form-group">
	                <label for="billing_address" class="text-label">Cím</label>

	                <div class="">
	                    <input id="billing_address" 
	                    	type="text" 
	                    	class="form-control" 
	                    	name="billing_address" 
	                    	v-model="checkoutData.billingData.address.description" 
	                    	@input="billingAutocomplete" 
	                    	required
	                    	autocomplete="off"
	                    	@keyup.enter="onInputLeave"
	                    	@change="sendFormData">
	                    <ul class="autocomplete-results" v-if="billingResults">
					     	<li class="autocomplete-result" 
					     		v-for="result in billingResults" 
					     		@click="checkoutData.billingData.address = result; getShipping(result)">
					      		{{ result.description }}
					      	</li>
					    </ul>
	                </div>
	            </div>

	        </form>

	        <div class="form-group">
                <label class="form-check" for="need_shipping">
                    A számlázási és szállítási adatok különböznek
                    <input class="form-check-input" 
                    	type="checkbox" 
                    	name="need_shipping" 
                    	id="need_shipping" 
                    	v-model="checkoutData.needShipping"
                    	@change="sendFormData">

                    <span class="checkmark"></span>
                </label>
            </div>

            <h2 v-if="checkoutData.needShipping">szállítási adatok</h2>

            <form class="shipping-address" method="POST" action="" v-if="checkoutData.needShipping">

	            <div class="form-group">
	                <label for="shipping_name" class="text-label">Név</label>

	                <div class="">
	                    <input id="shipping_name" 
	                    	type="text" 
	                    	class="form-control" 
	                    	name="shipping_name" 
	                    	v-model="checkoutData.shippingData.name" 
	                    	required 
	                    	autocomplete="shipping_name"
	                    	@change="sendFormData">
	                </div>
	            </div>

	            <div class="form-group">
	                <label for="shipping_address" class="text-label">Cím</label>

	                <div class="">
	                    <input id="shipping_address" 
	                    	type="text" 
	                    	class="form-control" 
	                    	name="shipping_address" 
	                    	v-model="checkoutData.shippingData.address.description" 
	                    	@input="shippingAutocomplete" 
	                    	required 
	                    	autocomplete="off"
	                    	@blur="onInputLeave" 
	                    	@keyup.enter="onInputLeave"
	                    	@change="sendFormData">
	                    <ul class="autocomplete-results" v-if="shippingResults">
					     	<li class="autocomplete-result" 
					     		v-for="result in shippingResults" 
					     		@click="checkoutData.shippingData.address = result; getShipping(result)">
					      		{{ result.description }}
					      	</li>
					    </ul>
	                </div>
	            </div>
	            
	        </form>

	        <div class="form-group">
	            <label for="other" class="text-label">Megjegyzés</label>

	            <div class="">
	                <textarea name="other" id="other" cols="30" rows="10" @change="sendFormData"></textarea>
	            </div>
	        </div>

	        <h2>Átvétel módja</h2>

			<div class="mode">
		        <div class="local-pickup" 
		        	:class="[checkoutData.shippingMethod == 'local_pickup' ? 'active' : '']" 
		        	@click="checkoutData.shippingMethod = 'local_pickup'; sendFormData();">
		        	<div class="pikto"></div>
		        	Személyes átvétel
		        	<strong class="price">0 Ft</strong>
		        	<p>Lórum ipse fesket a haras iges ellen. A télvesztőn a villa, kezgék, golydák helmi fogyantok mellett szivincemet tárdult jócska közés szlás, magyos jornakantázs, a kezes alané fence, bunya repica apasz szabáj és trium pajdozás kezes zugaság. A fent gyülen nella alatt lehet ványságot kistegetnie a tajtánukból való lili, vagy a tajtánukba való tumagás miatt. A tramákkal darcszerű nekezések számára hasztás nerengés, mert a tognálaikban egy fojtó zatár kodt.</p>
		        </div>
		        <div class="home-delivery" 
		        	:class="[checkoutData.shippingMethod == 'home_delivery' ? 'active' : '']" 
		        	@click="checkoutData.shippingMethod = 'home_delivery'; sendFormData();">
		        	<div v-if="homeDeliveryAvailable">
		        		<div class="pikto"></div>
			        	Házhozszállítás
			        	<strong class="price">{{ shippingPrice }} Ft</strong>
			        	<p>Lórum ipse fesket a haras iges ellen. A télvesztőn a villa, kezgék, golydák helmi fogyantok mellett szivincemet tárdult jócska közés szlás, magyos jornakantázs, a kezes alané fence, bunya repica apasz szabáj és trium pajdozás kezes zugaság. A fent gyülen nella alatt lehet ványságot kistegetnie a tajtánukból való lili, vagy a tajtánukba való tumagás miatt. A tramákkal darcszerű nekezések számára hasztás nerengés, mert a tognálaikban egy fojtó zatár kodt.</p>
		        	</div>
		        	<div v-else>
		        		Nem szállítunk ide!
		        	</div>
		        	
		        </div>
			</div>
	         <h2>Fizetési mód</h2>
			<div class="payment">
		        <div class="cod" 
		        	:class="[checkoutData.paymentMethod == 'cod' ? 'active' : '']" 
		        	@click="checkoutData.paymentMethod = 'cod'; sendFormData();"><div class="pikto"></div>Utánvét
		        </div>
		        <div class="credit-card soon" 
		        	:class="[checkoutData.paymentMethod == 'credit_card' ? 'active' : '']" 
		        	@click="checkoutData.paymentMethod = 'credit_card'; sendFormData();"><div class="pikto"></div>Bankkártya
		        </div>
		        <div class="bac soon" 
		        	:class="[checkoutData.paymentMethod == 'bac' ? 'active' : '']" 
		        	@click="checkoutData.paymentMethod = 'bac'; sendFormData();"><div class="pikto"></div>Átutalás</div>

		    	</div>
			</div>
	</div>
</template>

<script>
	export default {
        props: {
            currentRoute: String
        },
        data() {
            return {
            	checkoutData: {
            		needShipping: false,
            		shippingMethod: '',
            		paymentMethod: 'cod',
            		billingData: {
            			name: '',
            			address: {},
            			shippingPrice: 0
            		},
            		shippingData: {
            			name: '',
            			address: {},
            			shippingPrice: 0
            		},
            	},
            	billingResults: [],
            	shippingResults: [],
            	shipping: {
            		distance: 0,
            		price: 0,
            	},
            	cartTotal: 0,
            }
        },
        computed: {
            homeDeliveryAvailable: function () {
            	if (this.checkoutData.needShipping) {
            		if (this.checkoutData.shippingData.address.description) {
	            		var is_in_hungary = this.checkoutData.shippingData.address.description.indexOf('Magyarország') >= 0;
	            		var shipping_price = this.checkoutData.shippingData.shippingPrice;
	            	}
            	} else {
            		if (this.checkoutData.billingData.address.description) {
	            		var is_in_hungary = this.checkoutData.billingData.address.description.indexOf('Magyarország') >= 0;
	            		var shipping_price = this.checkoutData.billingData.shippingPrice;
	            	}
            	}
            	if (shipping_price != -1 || this.cartTotal > 45000) {
            		if (is_in_hungary) {
            			return true;
            		} else {
            			return false;
            		}
            	} else {
            		return false;
            	}
            },
            shippingPrice: function () {
            	return this.checkoutData.needShipping ? this.checkoutData.shippingData.shippingPrice : this.checkoutData.billingData.shippingPrice;
            }
        }, 
        beforeMount() {
            if (Vue.localStorage.get('shop_cart_items') != null && this.currentRoute == 'checkout.shop') {
                this.items = JSON.parse(Vue.localStorage.get('shop_cart_items'));
            }
            if (Vue.localStorage.get('pre_order_cart_items') != null && this.currentRoute == 'checkout.pre-order') {
                this.items = JSON.parse(Vue.localStorage.get('pre_order_cart_items'));
            }
        },
        mounted() {
            this.$root.$on('get-total', (total) => {
                this.cartTotal = total;
            });
        },
        methods: {
            billingAutocomplete(value) {
                axios.get('/autocomplete/' + this.checkoutData.billingData.address.description)
                    .then(
                        response => {
                          	this.billingResults = response.data.autocomplete.predictions;
                        }
                    ).catch();
            },
            shippingAutocomplete(value) {
                axios.get('/autocomplete/' + this.checkoutData.shippingData.address.description)
                    .then(
                        response => {
                          	this.shippingResults = response.data.autocomplete.predictions;
                        }
                    ).catch();
            },
            getShipping() {
            	this.billingResults = [];
            	this.shippingResults = [];
            	if (this.checkoutData.needShipping) {
            		var address2 = this.checkoutData.shippingData.address;
            	} else {
            		var address2 = this.checkoutData.billingData.address;
            	}
                axios.get('/calculate-shipping?address=' + JSON.stringify(address2))
                    .then(
                        response => {
                          	this.shipping.distance = response.data.distance;
                          	this.shipping.price = this.calculateShippingPrice();
                          	if (this.checkoutData.needShipping) {
                          		this.checkoutData.shippingData.shippingPrice = this.shipping.price;
                          	} else {
                          		this.checkoutData.billingData.shippingPrice = this.shipping.price;
                          	}
                        }
                    ).catch();
            },
            calculateShippingPrice() {
            	if (this.shipping.distance < 50000) {return this.cartTotal > 45000 ? 0 : 1000;} 
            	else if (this.shipping.distance < 100000) {return this.cartTotal > 45000 ? 0 : 2000;} 
            	else if (this.shipping.distance < 150000) {return this.cartTotal > 45000 ? 0 : 3000;} 
            	else if (this.shipping.distance < 200000) {return this.cartTotal > 45000 ? 0 : 4000;} 
            	else if (this.shipping.distance < 250000) {return this.cartTotal > 45000 ? 0 : 5000;} 
            	else {return -1;}
            },
            onInputLeave() {
            	if (!this.checkoutData.needShipping) {
            		if (this.billingResults.length > 0) {
            			this.checkoutData.billingData.address = this.billingResults[0]; 
            			this.getShipping(this.billingResults[0])
            		}
            	} else {
            		if (this.shippingResults.length > 0) {
            			this.checkoutData.shippingData.address = this.shippingResults[0]; 
            			this.getShipping(this.shippingResults[0])
            		}
            	}
            	
            }, 
            sendFormData() {
                this.$root.$emit('send-form-data', this.checkoutData);
            }
        }
    }
</script>