var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "gray-knife pre" }, [
      _c("div", { staticClass: "wrap pre" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "timeline" },
          [
            _c(
              "div",
              { staticClass: "month-bar" },
              _vm._l(_vm.months, function(month) {
                return _c(
                  "div",
                  { staticClass: "month", style: _vm.setWidth(month.length) },
                  [_c("span", [_vm._v(_vm._s(_vm.getMonthName(month.month)))])]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(_vm.cattles, function(cattle) {
              return _c(
                "div",
                {
                  staticClass: "cutting select",
                  style: _vm.calculateCattlePosition(cattle)
                },
                [
                  _c("div", { staticClass: "knife" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "mark" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "cut-cow" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "range" }, [
                    _c("div", {
                      staticClass: "avaliable",
                      style: _vm.setPercentWidth(cattle)
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "hover-cut-card" }, [
                    _c("div", { staticClass: "arrow-up" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "cut-card",
                        on: {
                          click: function($event) {
                            return _vm.selectCattle(cattle)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "cut-card-title" }, [
                          _vm._v(_vm._s(cattle.type.name))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "cut-card-cow" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "range" }, [
                          _c("div", {
                            staticClass: "avaliable",
                            style: _vm.setPercentWidth(cattle)
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "percent" }, [
                          _vm._v(
                            _vm._s(_vm.getCattlePercent(cattle).toFixed(2)) +
                              "%"
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "date" }, [
                          _vm._v(_vm._s(cattle.cut_date))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "enar" }, [
                          _vm._v("enar szám:")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "hu-number" }, [
                          _vm._v(_vm._s(cattle.enar))
                        ])
                      ]
                    )
                  ])
                ]
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "beef-sheet" }, [
      _vm.selected
        ? _c("div", { staticClass: "center-center" }, [
            _c("table", [
              _c("tr", [
                _c("td", [_vm._v("vágás időpontja")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.selected.cut_date))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("típus")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.selected.type.name) +
                      " " +
                      _vm._s(_vm.selected.is_grown_up == 0 ? "borjú" : "")
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("ENAR szám")]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.selected.enar))])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Bontás típus")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.selected.cut_type == "hungarian"
                        ? "magyar"
                        : "amerikai"
                    ) + " bontás"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("maradt")]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "range" }, [
                    _c("div", {
                      staticClass: "avaliable",
                      style: _vm.setPercentWidth(_vm.selected)
                    })
                  ]),
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.getCattlePercent(_vm.selected).toFixed(2)) +
                      "%\n                    "
                  )
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "knife-description" }, [
      _c("p", { staticClass: "number-knife-one" }, [_vm._v("1.")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-knife" }, [
        _vm._v(
          "Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot."
        )
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "number-knife-one" }, [_vm._v("2.")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-knife" }, [
        _vm._v("A zsútos alomok azt szengetették, hogy a hadt vikezések.")
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "number-knife-one" }, [_vm._v("3.")]),
      _vm._v(" "),
      _c("p", { staticClass: "text-knife" }, [
        _vm._v(
          "Mányom szengetették nem rönges eles baságokról ketitálnia hozzá."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }