/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import VueLocalStorage from 'vue-localstorage';

require('./bootstrap');

window.Vue = require('vue');

window.VueLocalStorage = VueLocalStorage;
Vue.use(VueLocalStorage);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('shop-product-list', require('./components/ShopProductList.vue').default);
Vue.component('shop-filter', require('./components/ShopFilter.vue').default);
Vue.component('shop-cart', require('./components/ShopCart.vue').default);
Vue.component('checkout-cart', require('./components/CheckoutCart.vue').default);
Vue.component('checkout-form', require('./components/CheckoutForm.vue').default);
Vue.component('order-status-switcher', require('./components/OrderStatusSwitcher.vue').default);
Vue.component('pre-order-status-switcher', require('./components/PreOrderStatusSwitcher.vue').default);
Vue.component('vertikum', require('./components/Vertikum.vue').default);

Vue.component('pre-order-cart', require('./components/PreOrderCart.vue').default);
Vue.component('pre-order-filter', require('./components/PreOrderFilter.vue').default);
Vue.component('pre-order-timeline', require('./components/PreOrderTimeline.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});

//require('./canvas');

$('.collapsible').on('click', function(e) {
    if ($('.filters').is(':visible')) {
    	$('.collapsible').removeClass('open');
        $('.filters').fadeOut(300);
    } else {
    	$('.collapsible').addClass('open');
        $('.filters').fadeIn(300);
    }
});

$('#layer .nav').on('click', function(e) {
    $('#layer').toggleClass('open');
});

$('.mini-cart').on('click', function(e) {
    $('html,body').animate({
	 	scrollTop: $('#cart').offset().top
	 }, 500);
});

$(window).scroll(function() {
	//if ($(window).innerWidth() > 750  && ($('.images').height() > $('#leiras').height())) {

	   	var main = $('main').height();
	   	var cart = $('#cart').height();
	   	var pozicio = main - cart - 485;

		if ($(this).scrollTop() > pozicio) 
			$('.mini-cart').removeClass('open');
		else
			$('.mini-cart').addClass('open');
	//}
});