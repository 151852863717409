var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gray" }, [
    _c("div", { staticClass: "wrap" }, [
      _c(
        "div",
        { staticClass: "cards" },
        _vm._l(_vm.items, function(item) {
          return _c(
            "div",
            {
              staticClass: "card-cow",
              class: [_vm.isInCart(item) ? "selected" : ""]
            },
            [
              _c("div", { staticClass: "code" }, [
                _c("div", { staticClass: "name-kg" }, [
                  _c("div", { staticClass: "part-name" }, [
                    _vm._v(_vm._s(item.cut.part.name))
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(item.cut.price_huf) + " Ft/kg")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "frozen" })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "pikto-cow" }),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "info card", attrs: { cellspacing: "0" } },
                [
                  _c("tr", { staticClass: "last-line" }, [
                    _c("td", [_vm._v(_vm._s(item.cattle.type.name))]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(item.cattle.cut_type))])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "enarcod" }, [
                _vm._v("ENAR kód: "),
                _c("b", [_vm._v(_vm._s(item.cattle.enar))])
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "info card", attrs: { cellspacing: "0" } },
                [
                  _c("tr", { staticClass: "last-line" }, [
                    _c("td", [_c("b", [_vm._v(_vm._s(item.weight) + " kg")])]),
                    _vm._v(" "),
                    _c("td", [
                      _c("span", [
                        _c("b", [
                          _vm._v(
                            _vm._s(item.cut.price_huf * item.weight) + " Ft"
                          )
                        ])
                      ])
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.isInCart(item)
                ? _c(
                    "div",
                    {
                      staticClass: "button select remove",
                      on: {
                        click: function($event) {
                          return _vm.removeFromCart(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    kosárból kiveszem\n                "
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "button select",
                      on: {
                        click: function($event) {
                          return _vm.addToCart(item)
                        }
                      }
                    },
                    [_vm._v("\n                    kosárba\n                ")]
                  )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "pagination" },
        [
          _c("li", [
            _c(
              "a",
              {
                class: { disabled: _vm.page == 1 },
                attrs: { href: "/shop?page=" + (_vm.page - 1) }
              },
              [_c("i", { staticClass: "fa fa-chevron-left" })]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.laravelData.last_page, function(index) {
            return _vm.laravelData.last_page < 10 ||
              index == 1 ||
              index == _vm.laravelData.last_page ||
              _vm.page == index ||
              (index > _vm.page - 3 && index < _vm.page) ||
              (index > _vm.page && index < _vm.page + 3)
              ? _c("li", [
                  _vm.laravelData.last_page > 10 &&
                  _vm.page > 4 &&
                  _vm.page < _vm.laravelData.last_page - 3 &&
                  index == _vm.laravelData.last_page
                    ? _c("span", [_vm._v("...")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      class: { current: _vm.page == index },
                      attrs: { href: "/shop?page=" + index }
                    },
                    [_vm._v("\n                   " + _vm._s(index))]
                  ),
                  _vm._v(" "),
                  _vm.laravelData.last_page > 10 &&
                  _vm.page > 4 &&
                  _vm.page < _vm.laravelData.last_page - 3 &&
                  index == 1
                    ? _c("span", [_vm._v("...")])
                    : _vm._e()
                ])
              : _vm._e()
          }),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: { disabled: _vm.page == _vm.laravelData.last_page },
                attrs: { href: "/shop?page=" + (+_vm.page + 1) }
              },
              [_c("i", { staticClass: "fa fa-chevron-right" })]
            )
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }