<template>
    <div>   
        <div class="wrap canvas-container">
            <canvas id="myCanvas" height="200px" width="870px"></canvas>
        </div>

        <div class="map" :style="'background-position: ' + this.current_dot.mapPosition.x + 'px' + ' ' + this.current_dot.mapPosition.y + 'px'">
            <div class="wrap">
                <transition name="slide-fade" mode="out-in">
                    <div class="topic show" :key="current_dot.title.text">
                        <h1>{{ current_dot.title.text }}</h1>
                        <p class="text" v-for="paragraph in current_dot.content.paragraphs">
                            {{ paragraph }}
                        </p>
                        <div class="first-page-picture" :class="current_dot.content.imageClass"></div>

                        <div class="nav-left" v-if="current_dot.left">
                            <div class="button menu" 
                                :class="dots[id].title.class"
                                v-for="id in current_dot.left"
                                @click="setupAnimation(id)">
                                <i class="fa fa-angle-left"></i>
                                {{ dots[id].title.text }}
                            </div>
                        </div>

                        <div class="nav-right" v-if="current_dot.right">
                            <div class="button menu" 
                                :class="dots[id].title.class"
                                v-for="id in current_dot.right"
                                @click="setupAnimation(id)">
                                {{ dots[id].title.text }}
                                <i class="fa fa-angle-right"></i>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        data() {
            return {
            	lines: [
                    {
                        start:[31,36],
                        end:[173,165],
                        bezier:[66,143]
                    },
                    {
                        start:[31,36],
                        end:[29,165],
                        bezier:[16,100]
                    },
                    {
                        start:[31,36],
                        end:[204,92],
                        bezier:[95,91]
                    },
                    {
                        start:[31,36],
                        end:[256,36],
                        bezier:[144,62]
                    },
                    {
                        start:[256,36],
                        end:[393,165],
                        bezier:[276,139]
                    },
                    {
                        start:[256,36],
                        end:[438,92],
                        bezier:[321,90]
                    },
                    {
                        start:[256,36],
                        end:[481,36],
                        bezier:[367,62]
                    },
                    {
                        start:[481,36],
                        end:[707,36],
                        bezier:[597,62]
                    },
                    {
                        start:[707,36],
                        end:[601,92],
                        bezier:[677,88]
                    },
                    {
                        start:[707,36],
                        end:[662,165],
                        bezier:[712,110]
                    },
                    {
                        start:[707,36],
                        end:[808,135],
                        bezier:[732,104]
                    },
                    {
                        start:[707,36],
                        end:[836,49],
                        bezier:[764,63]
                    },
                ],
                dots: [
                    {
                        title: {
                            text: 'birtok',
                            class: '',
                            x: 6,
                            y: 15
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'birtok',
                        },
                        left: [],
                        right: [4,3,1,2],
                        mapPosition: {
                            x: 0,
                            y: 0
                        },
                        x:31,
                        y:36
                    },
                    {
                        title: {
                            text: 'bértartás',
                            class: '',
                            x: 136,
                            y: 197
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'bertartas',
                        },
                        left: [0],
                        right: [2,3],
                        mapPosition: {
                            x: -100*Math.sin(5/6*Math.PI),
                            y: 100*Math.cos(5/6*Math.PI)
                        },
                        x:173,
                        y:165
                    },
                    {
                        title: {
                            text: 'rólunk',
                            class: '',
                            x: 2,
                            y: 197
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'rolunk',
                        },
                        left: [0],
                        right: [1,3],
                        mapPosition: {
                            x: -100*Math.sin(Math.PI),
                            y: 100*Math.cos(Math.PI)
                        },
                        x:29,
                        y:165
                    },
                    {
                        title: {
                            text: 'takarmány',
                            class: '',
                            x: 162,
                            y: 124
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'takarmany',
                        },
                        left: [0],
                        right: [1,2],
                        mapPosition: {
                            x: -100*Math.sin(2/3*Math.PI),
                            y: 100*Math.cos(2/3*Math.PI)
                        },
                        x:204,
                        y:92
                    },
                    {
                        title: {
                            text: 'élőállat',
                            class: '',
                            x: 222,
                            y: 15
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'eloallat',
                        },
                        left: [0],
                        right: [7,6,5],
                        mapPosition: {
                            x: -100,
                            y: 0
                        },
                        x:256,
                        y:36
                    },
                    {
                        title: {
                            text: 'tenyésztés',
                            class: '',
                            x: 353,
                            y: 197
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'tenyesztes',
                        },
                        left: [4],
                        right: [6],
                        mapPosition: {
                            x: -100*Math.sin(5/6*Math.PI) - 100,
                            y: 100*Math.cos(5/6*Math.PI)
                        },
                        x:393,
                        y:165
                    },
                    {
                        title: {
                            text: 'ridegtartás',
                            class: '',
                            x: 393,
                            y: 124
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'ridegtartas',
                        },
                        left: [4],
                        right: [5],
                        mapPosition: {
                            x: -100*Math.sin(2/3*Math.PI) - 100,
                            y: 100*Math.cos(2/3*Math.PI)
                        },
                        x:438,
                        y:92
                    },
                    {
                        title: {
                            text: 'marhák',
                            class: '',
                            x: 452,
                            y: 15
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'marhak',
                        },
                        left: [4],
                        right: [8],
                        mapPosition: {
                            x: -200,
                            y: 0
                        },
                        x:481,
                        y:36
                    },
                    {
                        title: {
                            text: 'húsfeldolgozás',
                            class: 'long',
                            x: 639,
                            y: 15
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'husfeldolgozas',
                        },
                        left: [7],
                        right: [9,10,11,12],
                        mapPosition: {
                            x: -300,
                            y: 0
                        },
                        x:707,
                        y:36
                    },
                    {
                        title: {
                            text: 'érlelés',
                            class: '',
                            x: 574,
                            y: 124
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'erleles',
                        },
                        left: [8],
                        right: [10,11,12],
                        mapPosition: {
                            x: -100*Math.sin(5/4*Math.PI) - 300,
                            y: 100*Math.cos(5/4*Math.PI)
                        },
                        x:601,
                        y:92
                    },
                    {
                        title: {
                            text: 'füstölés',
                            class: '',
                            x: 629,
                            y: 197
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'fustoles',
                        },
                        left: [8],
                        right: [9,11,12],
                        mapPosition: {
                            x: -100*Math.sin(Math.PI) - 300,
                            y: 100*Math.cos(Math.PI)
                        },
                        x:662,
                        y:165
                    },
                    {
                        title: {
                            text: 'sous vide',
                            class: '',
                            x: 783,
                            y: 162
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'sous-vide',
                        },
                        left: [8],
                        right: [9,10,12],
                        mapPosition: {
                            x: -100*Math.sin(3/4*Math.PI) - 300,
                            y: 100*Math.cos(3/4*Math.PI)
                        },
                        x:808,
                        y:135
                    },
                    {
                        title: {
                            text: 'bontás',
                            class: '',
                            x: 810,
                            y: 80
                        },
                        content: {
                            paragraphs: [
                                'Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára marhák fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy redegtartás a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. Gúnyoz rezés majd hozzá padt kízeket, padt patajogó mánászot, hartot, velépépet, zsirászot vagy éppen mocsmányos vítást? Nívsátos ugyanis, hogy olyan kopápot morászjon, amelyben van ezekhez szorohozott kerő denzás (tatlapos).',
                                'Falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran takarmány számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon. A dondás vezdése (rizmusával: landást) ma már szinte falatatlan kálána egy fencesnek, a dönkök felő elsőjére és csaran dutamájára számítva fehehnek egyen, hogy ilyen sitlemettel kavar gvagást morászjon.'
                            ],
                            imageClass: 'bontas',
                        },
                        left: [8],
                        right: [9,10,11],
                        mapPosition: {
                            x: -100*Math.sin(1/2*Math.PI) - 300,
                            y: 100*Math.cos(1/2*Math.PI)
                        },
                        x:836,
                        y:49
                    }
                ],
                canvas: 0,
                ctx: 0,
                animation: 0,
                position: 0,
                red_dot: {
                    x: 31,
                    y: 36
                },
                current_dot: 0,
                graph: 0,
                test: 'birtok'
            }
        },
        created: function () {
            this.current_dot = this.dots[0];
        },
        mounted: function () {
            this.canvas = document.getElementById("myCanvas");
            this.ctx = this.canvas.getContext("2d");
            this.canvas.addEventListener('mousemove', this.cursor);
            this.canvas.addEventListener('click', this.animateCheck);
            this.resizeCanvas();
            this.draw_canvas();
            this.draw_red_dot(this.red_dot);
        },
        methods: {
            resizeCanvas() {
                let rect = this.canvas.getBoundingClientRect();

                this.canvas.width = rect.width * devicePixelRatio;
                this.canvas.height = rect.height * devicePixelRatio;

                this.ctx.scale(devicePixelRatio, devicePixelRatio);

                this.canvas.style.width = rect.width + 'px';
                this.canvas.style.height = rect.height + 'px';
            },
            draw_dot(dot) {
                this.ctx.beginPath();
                this.ctx.arc(dot.x, dot.y, 4, 0, 2 * Math.PI, true);
                this.ctx.fillStyle = "#333333";
                this.ctx.fill();
                this.ctx.font = '14px "Open Sans", sans-serif';
                this.ctx.fillText(dot.title.text.toUpperCase(), dot.title.x, dot.title.y);
            },
            draw_red_dot(red_dot) {
                this.ctx.beginPath();
                this.ctx.arc(red_dot.x, red_dot.y, 6, 0, 2 * Math.PI, true);
                this.ctx.fillStyle = "#DC4228";
                this.ctx.fill();
            },
            draw_line(line) {
                this.ctx.beginPath();
                this.ctx.moveTo(line.start[0], line.start[1]);
                this.ctx.quadraticCurveTo(line.bezier[0], line.bezier[1], line.end[0], line.end[1]);
                this.ctx.strokeStyle = "#A3A3A3";
                this.ctx.stroke();
            },
            draw_canvas() {
                this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
                this.lines.forEach(line => this.draw_line(line));
                this.dots.forEach(dot => this.draw_dot(dot));
            },
            bezier(pts) {
                return function (t) {
                    for (var a = pts; a.length > 1; a = b)  // do..while loop in disguise
                        for (var i = 0, b = [], j; i < a.length - 1; i++)  // cycle over control points
                            for (b[i] = [], j = 0; j < a[i].length; j++)  // cycle over dimensions
                                b[i][j] = a[i][j] * (1 - t) + a[i+1][j] * t;  // interpolation
                    return a[0];
                }
            },
            makePolyPoints(pathArray){
                var points = [];

                for (var i = 1; i < pathArray.length; i++) {
                    var startPt = pathArray[i - 1];
                    var endPt = pathArray[i];
                    var dx = endPt[0] - startPt[0];
                    var dy = endPt[1] - startPt[1];
                    var dr = 6 - 3;
                    for (var n = 0; n <= 10; n++) {
                        var x = startPt[0] + dx * n/10;
                        var y = startPt[1] + dy * n/10;
                        if (i < 2 || i > pathArray.length - 2) {
                            if (i < 3) {
                                var r = 6 - dr * n/10;
                            } else {
                                var r = 3 + dr * n/10;
                            }
                            points.push({x: x, y: y, r: r});
                        } else {
                            points.push({x: x, y: y, r: 3});
                        }
                    }
                }
                return(points);
            },
            setupAnimation(index) {
                this.current_dot = this.dots[index];

                var dir = 'positive';
                var jump = false;
                var line = this.lines.filter(line => 
                    line.start[0] == this.red_dot.x && 
                    line.start[1] == this.red_dot.y && 
                    line.end[0] == this.dots[index].x && 
                    line.end[1] == this.dots[index].y
                );

                if (line.length != 1) {
                    line = this.lines.filter(line => 
                        line.end[0] == this.red_dot.x && 
                        line.end[1] == this.red_dot.y && 
                        line.start[0] == this.dots[index].x && 
                        line.start[1] == this.dots[index].y
                    );
                    dir = 'negative';
                    if (line.length != 1) {
                        jump = true;
                    } else {
                        line = line[0];
                    }
                } else {
                    line = line[0];
                }

                if (!jump) {
                    var pathArray = [];

                    if (dir == 'positive') {
                        var b = this.bezier([[line.start[0], line.start[1]], [line.bezier[0], line.bezier[1]], [line.end[0], line.end[1]]]);
                    } else {
                        var b = this.bezier([[line.end[0], line.end[1]], [line.bezier[0], line.bezier[1]], [line.start[0], line.start[1]]]);
                    }
                    
                    for (var t = 0; t <= 10; t++) pathArray.push(b(t / 10));

                    this.graph = this.makePolyPoints(pathArray);

                    this.graph.push(this.graph[this.graph.length - 1]);

                    this.animate(this);

                    this.position = 0;
                } else {
                    this.red_dot.x = this.dots[index].x;
                    this.red_dot.y = this.dots[index].y;

                    this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
                    this.ctx.save();
                    this.draw_canvas();  
                    this.draw_red_dot(this.red_dot);
                    this.ctx.restore();
                }
            },
            animateCheck(event) {
                if (event.isTrusted) {
                    var filterX = this.dots.filter(dot => event.offsetX < dot.x + 10 && event.offsetX > dot.x - 10);
                    var filterXY = filterX.filter(dot => event.offsetY < dot.y + 10 && event.offsetY > dot.y - 10);

                    if (filterXY.length == 1) {
                        var index = this.dots.findIndex(dot => dot.x == filterXY[0].x && dot.y == filterXY[0].y);
                        this.setupAnimation(index);
                    }
                }
            },
            animate(self) {

                if (self.animation) {
                    window.cancelAnimationFrame(self.animation)
                }

                var animate = () => self.animate(self);

                setTimeout(function() {
                    self.animation = window.requestAnimationFrame(function() {
                        self.animate(self);
                    });

                    self.position += 10;
                    if (self.position > self.graph.length - 1) {
                        return;
                    }

                    self.red_dot.x = self.graph[self.position].x;
                    self.red_dot.y = self.graph[self.position].y;
                    var range = self.graph[self.position].r;

                    self.ctx.clearRect(0,0,self.canvas.width,self.canvas.height);
                    self.ctx.save();
                    self.draw_canvas();
                    self.ctx.beginPath();
                    self.ctx.arc(self.red_dot.x, self.red_dot.y, range, 0, 2 * Math.PI, true);
                    self.ctx.fillStyle = "#DC4228";
                    self.ctx.fill();    
                    self.ctx.restore();

                }, 1000/60);
            },
            cursor(event) {
                if (event.isTrusted) {
                    var filterX = this.dots.filter(dot => event.offsetX < dot.x + 10 && event.offsetX > dot.x - 10);
                    var filterXY = filterX.filter(dot => event.offsetY < dot.y + 10 && event.offsetY > dot.y - 10);

                    if (filterXY.length > 0) {
                        $('#myCanvas').css('cursor', 'pointer');
                    } else {
                        $('#myCanvas').css('cursor', 'default');
                    }
                }
            }
        }
    }
</script>


