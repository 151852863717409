var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mini-cart",
        class: [_vm.items.length > 0 ? "open" : "close"]
      },
      [
        _c("i", {
          staticClass: "fa fa-shopping-basket",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v(" "),
        _c("p", [
          _c("b", [_vm._v(_vm._s(_vm.items.length))]),
          _vm._v(" termék\n        ")
        ])
      ]
    ),
    _vm._v(" "),
    _vm.items.length > 0
      ? _c("div", { attrs: { id: "cart" } }, [
          _c("h4", [_vm._v("Húspiac kosár")]),
          _vm._v(" "),
          _c(
            "table",
            { staticClass: "cart" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.items, function(item) {
                return _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(item.cut.part.name) + " "),
                    _c("span", [
                      _vm._v("(" + _vm._s(item.cut.price_huf) + " Ft/kg)")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.weight) + " kg")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(item.cut.price_huf * item.weight) + " Ft")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "span",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeFromCart(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-trash" })]
                    )
                  ])
                ])
              }),
              _vm._v(" "),
              _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.total) + " Ft")]),
                _vm._v(" "),
                _c("td")
              ])
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "center" }, [
            _c(
              "a",
              {
                staticClass: "button next",
                attrs: { href: _vm.checkoutUrl + "?type=shop" }
              },
              [_vm._v("Megrendelem")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Hústípus")]),
      _vm._v(" "),
      _c("th", [_vm._v("Mennyiség")]),
      _vm._v(" "),
      _c("th", [_vm._v("Becsült végösszeg")]),
      _vm._v(" "),
      _c("th")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }