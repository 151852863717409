<template>
    <div class="control">
        <input type="radio" value="processing" v-model="status">
        <input type="radio" value="processed" v-model="status">
        <input type="radio" value="delivered" v-model="status">
    </div>
</template>

<script>
    export default {
        props: {
            currentStatus: String,
            orderId: String
        },
        data() {
            return {
                status: ''
            }
        },
        mounted() {
            this.status = this.currentStatus;
        },
        watch: {
            status: function () {
                this.changeStatus();
            }
        },
        methods: {
            changeStatus() {
                axios.post('/order/update/' + this.orderId, {status: this.status})
                    .then(
                        response => {
                            if (this.status == 'processed') {
                                $('#' + this.orderId).removeClass('has-background-success');
                                $('#' + this.orderId).removeClass('has-background-white');
                                $('#' + this.orderId).addClass('has-background-warning');
                            }
                            if (this.status == 'processing') {
                                $('#' + this.orderId).removeClass('has-background-success');
                                $('#' + this.orderId).addClass('has-background-white');
                                $('#' + this.orderId).removeClass('has-background-warning');
                            }
                            if (this.status == 'delivered') {
                                $('#' + this.orderId).addClass('has-background-success');
                                $('#' + this.orderId).removeClass('has-background-white');
                                $('#' + this.orderId).removeClass('has-background-warning');
                            }
                        }
                    ).catch();
            }
        }
    }
</script>