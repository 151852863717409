var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "control" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.status,
          expression: "status"
        }
      ],
      attrs: { type: "radio", value: "processing" },
      domProps: { checked: _vm._q(_vm.status, "processing") },
      on: {
        change: function($event) {
          _vm.status = "processing"
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.status,
          expression: "status"
        }
      ],
      attrs: { type: "radio", value: "processed" },
      domProps: { checked: _vm._q(_vm.status, "processed") },
      on: {
        change: function($event) {
          _vm.status = "processed"
        }
      }
    }),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.status,
          expression: "status"
        }
      ],
      attrs: { type: "radio", value: "delivered" },
      domProps: { checked: _vm._q(_vm.status, "delivered") },
      on: {
        change: function($event) {
          _vm.status = "delivered"
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }